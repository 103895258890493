body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Remove extra spaca from the .event-filter-buttons */
.event-filter-buttons div div {
  margin-bottom: 0;
  margin-top: 0;
}

/* Remove unused white space from the "customizable-datagrid" button (select columns button) from the lists */
/* https://github.com/fizix-io/ra-customizable-datagrid */
div:has(+ .customizable-datagrid) {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  z-index: 9;
  margin-right: 0 !important;
}

/* Change Table header color */
.customizable-datagrid th {
  background-color: #f0f0f0;
}

.simpleShowLayoutContainer {
  padding-bottom: 0 !important;
}

.section-divider {
  margin: 2rem 0;
}
